import React, { useState } from 'react';
import { Alert } from "react-bootstrap";

function AlertDismissibleExample(data) {
  const [show, setShow] = useState(true);

  if (show && data.show) {
    return (
        <Alert variant={data.mode} onClose={() => setShow(false)} dismissible>
          <p className={"text-left"}>
            {data.message}
          </p>
        </Alert>
    );
  }
  return "";
}

export default AlertDismissibleExample