import './App.css';
import { Button, Card, Container, FormControl, InputGroup, Modal, Nav, Table } from "react-bootstrap";
import { useState } from "react";
import CustomAlert from "./Components/CustomAlert";

const AlexaRank = require('alexa-rank-nodejs').default;

function App() {
  const [domain, setDomain] = useState(true);
  const [alert, setAlert] = useState({
    show: false,
    mode: "primary",
    message: ""
  });
  const [spinner, setSpinner] = useState(false);
  const [rank, setRank] = useState(true);
  const [similarSites, setSmilarSites] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onSubmit = async () => {
    setAlert({
      show: true,
      mode: "info",
      message: `${domain} alan adı için alexa istatistik verileri hesaplanıyor..`
    })
    setSpinner(true)
    let data = await AlexaRank.siteinfo(domain);
    data = data.data;
    console.log(data);
    setRank(data.site_rank)
    setSmilarSites(data.similar_sites.map((site) =>
        <a
            className={"d-block"}
            href={"https://www.google.com/search?q=site:" + site}
            target={"_blank"}
        >
          {site}
        </a>
    ))
    setKeywords(data.top_keywords.map((keyword) =>
        <a
            className={"d-block"}
            href={"https://www.google.com/search?q=" + keyword}
            target={"_blank"}
        >
          {keyword}
        </a>
    ))
    handleShow()
    setSpinner(false);
    setAlert({
      show: true,
      mode: "success",
      message: `Alexa istatistik hesaplama işlemi başarıyla tamamlandı. Domain: ${domain}`
    })
  }
  return (
      <Container className={"pt-3"}>
        <CustomAlert
            show={alert.show}
            mode={alert.mode}
            message={alert.message}
        />
        <Card>
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey="#first">
              <Nav.Item>
                <Nav.Link href="#first">Alexa Rank Checker</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body>
            <InputGroup className="mb-3">
              <FormControl
                  size={"lg"}
                  placeholder="Enter your domain (ex: google.com)"
                  aria-label="Enter your domain (ex: google.com)"
                  aria-describedby="basic-addon2"
                  onChange={(e) => setDomain(e.target.value)}
                  disabled={spinner}
              />
              <InputGroup.Append>
                <Button
                    size={"lg"}
                    variant="primary"
                    onClick={() => onSubmit()}
                    disabled={spinner}
                >
                  Calculate Rank
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Card.Body>
        </Card>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>"{domain}" Alexa Statistics</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <tbody>
              <tr>
                <td><strong>Dünya Sıralaması</strong></td>
                <td>#{rank}</td>
              </tr>
              <tr>
                <td><strong>Benzer Siteler</strong></td>
                <td>
                  {similarSites}
                </td>
              </tr>
              <tr>
                <td><strong>Anahtar Kelimeler</strong></td>
                <td>
                  {keywords}
                </td>
              </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
  );
}

export default App;