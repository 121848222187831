import React from 'react';
import { Nav, Navbar } from "react-bootstrap";

class Header extends React.Component {
  render() {
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand href="#home">React Alexa Rank</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto"/>
            <Nav>
              <Nav.Link
                  target={"_blank"}
                  href="https://www.github.com/ArdaGnsrn"
              >
                My Personal GitHub
              </Nav.Link>
              <Nav.Link
                  target={"_blank"}
                  href="https://www.ardagunsuren.com"
              >
                Powered By ArdaGnsrn
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    );
  }
}

export default Header;